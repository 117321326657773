<template>
  <div class="subject-container">
    <titleComponent title="待支付"></titleComponent>
    <van-cell-group inset>
      <van-field
        label="预约人"
        :model-value="data.userName"
        readonly
        colon
        class="cl_data_field"
      />
      <van-field
        label="电话"
        :model-value="data.userAccount"
        colon
        readonly
        class="cl_data_field"
      />
      <van-field
        label="床号"
        :model-value="data.userBed"
        colon
        readonly
        class="cl_data_field"
      />
      <van-field
        label="病区"
        :model-value="data.userArea"
        colon
        readonly
        class="cl_data_field"
      />
      <van-field
        label="折扣"
        :model-value="data.discounts"
        colon
        readonly
        class="cl_data_field"
      />
      <van-field
        label="金额(元)"
        :model-value="data.mony"
        colon
        readonly
        class="cl_data_field"
      />
      <van-field
        label="预约时间"
        :model-value="data.appointVisitTime"
        colon
        readonly
        class="cl_data_field"
      />
    </van-cell-group>
    <div class="bottom">
      <van-button type="primary" block @click="pMethod">确定支付</van-button>
    </div>
    <van-popup v-model:show="show" position="bottom">
      <div class="payment-box">
        <div class="info-1" @click="paymentFn">
          <div class="box">
            <div class="img-box">
              <img src="../../assets/Vector.png" alt="" />
            </div>
            <span>微信支付</span>
          </div>
        </div>
     <!--<div class="info-1" @click="zhifuFn">
          <div class="box">
            <div class="img-box">
              <img src="../../assets/Vector-1.png" alt="" />
            </div>
            <span>支付宝支付</span>
          </div>
        </div>  -->
      </div>
    </van-popup>
  </div>
</template>
<script>
import { h5PayFn, polling, getOpenId ,weiXinPay} from "@/api/home";
// import { polling } from "@/api/home";
import titleComponent from "../title.vue";
import { getlist } from "@/api/about";
import { Toast } from "vant";
import { dCionFn } from "@/utils/method.js";
import { Dialog } from 'vant';
import { ref } from "vue";
export default {
  setup() {
    const show = ref(false);
    const pMethod = () => {
      show.value = true;
    };
    return { show, pMethod };
  },
  components: {
    titleComponent,
  },
  name: "h5Payent",
  created() {
    this.id = this.$route.query.id;
    this.getlist();
   
  },
  mounted() {
    this.code=this.getUrlCode().code; //是否存在code
    if(this.code){
      this.isExistCode()
    }
  },
  data() {
    return {
        code: '',
        data:{},
    };
  },
  methods: {
    getlist() {
      getlist(this.id).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data.discounts == "0") {
            data.mony = 40;
          } else {
            data.mony = data.discounts * 4;
          }
          data.discounts = dCionFn(data.discounts, "2");
          this.data = data;
        } else {
          Toast.error("网络错误");
          this.$router.push({ path: "/about" });
        }
      });
    },
    isExistCode(){
      Dialog.confirm({
  message: '是否确认支付?',  
})
  .then(() => {
    // on confirm
    this.paymentFn()
  })
  .catch(() => {
    // on cancel
    window.location.href='https://tencent.pay.demo.zjyanke.com/#/about'
  });
    },
    pollingFn() {
      polling(this.data.appointId)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    zhifuFn() {
      Toast.fail("待实现");
    },
    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
    paymentFn() {
      const parter = {
        account: this.data.userAccount,
        name: this.data.userName,
        bed: this.data.userBed,
        area: this.data.userArea,
        amount: this.data.mony * 100,
        discounts:
          this.data.discounts.substring(0, 1) == "不"
            ? "0"
            : this.data.discounts.substring(0, 1),
        visitTime: this.data.appointVisitTime.substring(0, 12),
        startStamp: this.data.appointStartTime,
        endStamp: this.data.appointEndTime,
        startTime: "",
        endTime: "",
        openid: this.data.appointId,
      };
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        // let appid = "wxe50b90e51b1a54a5"; //微信APPid
        // let code = this.getUrlCode().code; //是否存在code
        // let local = `https://qzrv.zjtytech.cn/${window.location.hash}`;
        // if (code == null || code === "") {
        //   //不存在就打开上面的地址进行授权
        //   window.location.href =
        //     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        //     appid +
        //     "&redirect_uri=" +
        //     encodeURIComponent(local) +
        //     "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
        // } else {
        //   this.code = code;
        //   this.getOpenIdFn()
        // }
        Dialog.alert({
  message: '请用外部浏览器打开该链接！',
}).then(() => {
  // on close
  window.location.href = 'https://qzrv.zjtytech.cn:7002/user/';
});
      } else {

        h5PayFn(parter)
          .then((res) => {
            const url =
              JSON.parse(res.data.data).h5_url +
              "&redirect_url=" +
              encodeURIComponent(`https://qzrv.zjtytech.cn:7002/user/#/about`);
            window.location.href = url; // 覆盖当前页
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getOpenIdFn() {
      getOpenId(this.code).then(res => {
        this.weiXinPayFn(res.data.data)
      })
    },
    // 支付
    weiXinPayFn(val){
      const parter = {
        account: this.data.userAccount,
        name: this.data.userName,
        bed: this.data.userBed,
        area: this.data.userArea,
        amount: this.data.mony * 100,
        discounts:
          this.data.discounts.substring(0, 1) == "不"
            ? "0"
            : this.data.discounts.substring(0, 1),
        visitTime: this.data.appointVisitTime.substring(0, 12),
        startStamp: this.data.appointStartTime,
        endStamp: this.data.appointEndTime,
        startTime: "",
        endTime: "",
        openid: val,
        appointId:this.data.appointId
      };

     weiXinPay(parter)
          .then((res) => {
            this.onBridgeReady(res.data)
          })
          .catch((err) => {
            console.log(err);
          });
    },
    onBridgeReady(val) {
      const that = this
    // eslint-disable-next-line no-undef
    WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": val.appId,     //公众号ID，由商户传入
        "timeStamp": val.timeStamp,     //时间戳，自1970年以来的秒数
        "nonceStr": val.nonceStr,      //随机串
        "package": val.package,
        "signType": "RSA",     //微信签名方式：
        "paySign": val.paySign //微信签名
    },
    (res)=>{
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          that.$router.push({ path: "/about" });
          that.code=''
          window.location.href='https://tencent.pay.demo.zjyanke.com/#/about'
          return
        }else if (res.err_msg == "get_brand_wcpay_request:cancel") {
        that.code=''
        that.$toast.fail("用户取消支付!");
        setTimeout(()=>{
          window.location.href='https://tencent.pay.demo.zjyanke.com/#/about'
        },1000)
         
        } else {
          that.code=''
          that.$toast.fail("支付失败!");
          setTimeout(()=>{
          window.location.href='https://tencent.pay.demo.zjyanke.com/#/about'
        },1000)
         
        }

    });
},
},
};
</script>

<style lang="less" scoped>
.bottom {
  margin-top: 1100px;
  width: 100%;
  text-align: center;
}

.subject-container {
  width: 100%;
}

.van-button--block {
  margin: 0 auto;
  width: 1200px;
  bottom: 200px;
}

.payment-box {
  height: 13rem;

  .info-1 {
    height: 6rem;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    .box {
      display: flex;
      align-items: center;

      .img-box {
        height: 2rem;
        width: 2rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      span {
        font-size: 2rem;
        font-weight: 800;
        margin-left: 1rem;
      }
    }
  }

  .info-2 {
    height: 5rem;
  }
}
</style>
