import request from '@/utils/request'

export function login(data) {
    return request({
      url: 'client/user/login',
      method: 'post',
      data
    })
  }

  export function loginauth(data) {
    return request({
      url: 'client/user/register',
      method: 'post',
      data
    })
  }

  export function modify(data) {
    return request({
      url: 'client/user/modify',
      method: 'post',
      data
    })
  }

  export function getsms(data) {
    return request({
      url: 'client/user/captcha',
      method: 'post',
      data
    })
  }


  // 发送验证码
  export function verification(data) {
    return request({
      url: '/client/user/phoneVerify',
      method: 'post',
      data
    })
  }

  // 验证验证码
  export function registerVerify(data) {
    return request({
      url: '/client/user/registerVerify',
      method: 'post',
      data
    })
  }

  // 修改密码
  export function updatePassword(data) {
    return request({
      url: '/client/user/updatePassword',
      method: 'post',
      data
    })
  }