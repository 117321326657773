<template>
  <div class="ap">
    <van-swipe-cell v-for="(v, k) in data" :key="k" class="cl_vancell">
      <div class="cl_apply_table" @click="getEdit(v.bindId)">
        <van-cell-group inset class="cl_inset">
          <van-field
            label="姓名"
            colon="true"
            label-width="50"
            :model-value="v.bindName"
            readonly
          />
          <van-field
            label="账号/电话"
            colon="true"
            label-width="80"
            :model-value="v.bindAccount"
            readonly
          />
        </van-cell-group>
        <div class="cl_exmine">
          <van-icon name="arrow" class="cl_arrow" />
        </div>
      </div>
      <template #right>
        <van-button
          square
          text="删除"
          type="danger"
          @click="getdelete(v.bindId)"
          class="delete-button"
        />
      </template>
    </van-swipe-cell>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
export default {
  name: 'userComponent',
  props: {
    data: {
      required: true,
      type: Array,
    },
  },
  setup(props, context) {
    const router = useRouter();
    function getEdit(uid) {
      router.push({ path: '/adduser', query: { id: uid } });
    }
    function getdelete(id) {
      context.emit('change', id);
    }
    return {
      getEdit,
      getdelete,
    };
  },
};
</script>

<style scoped>
.ap {
  width: 80%;
  margin: 0 auto;
  /* border: 1px solid red; */
}
/* 滑动单元格 */
.cl_vancell {
  /* border: 1px solid black; */
  margin: 1rem auto;
  font-size: 1.2rem;
  background-color: #fff;
  width: 90%;
  border-radius: 1.2rem;
}
.cl_vancell:last-child {
  /* border: 2px solid red; */
  margin-bottom: 50rem;
}

/* 删除 */
.delete-button {
  height: 100%;
  width: 8rem;
}
.cl_apply_table {
  width: 90%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
::v-deep .van-cell-group {
  font-size: 1.5rem;
  margin: 0;
}
::v-deep .van-field {
  background-color: #fff;
}
.cl_exmine {
  width: 10%;
}
.cl_exmine p {
  font-size: 1.5rem;
  position: absolute;
  top: -0.5rem;
  right: 2rem;
  color: Turquoise;
}
.cl_arrow {
  position: absolute;
  font-size: 2rem;
  bottom: 3.5rem;
  right: -2rem;
  color: #9e9e9e;
}
.van-cell:after {
  border-bottom: 0px;
}
</style>
