<template>
  <div class="ap">
    <!-- <p>我的预约</p> -->
    <div class="cl_subscribe">
      <div @click="getBeall(1)">
        <p><span class="iconfont">&#xe628;</span></p>
        <p>全部</p>
      </div>
      <div @click="getBeall(2)">
        <p><span class="iconfont">&#xe604;</span></p>
        <p>待探视</p>
      </div>
      <div @click="getBeall(3)">
        <p><span class="iconfont">&#xe615;</span></p>
        <p>已结束</p>
      </div>
    </div>

    <div @click="getuser" class="box1">
      <p><img src="../../../../public/image/binding.png" alt="" /></p>
      <p class="textap">家人绑定</p>
    </div>
    <!-- <div class="cl_subscribe1">
      <div @click="getuser" class="box1">
        <p><img src="../../../../public/image/binding.png" alt=""></p>
        <p class="textap">家人绑定</p>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "subscribeComponent",
  data() {
    return {};
  },
  methods: {
    getBeall(val) {
      sessionStorage.setItem("id", val);
      this.$router.push({ path: "/mytask" });
    },
    getuser() {
      this.$router.push({ path: "/management" });
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "iconfont logo";
  src: url("https://at.alicdn.com/t/font_985780_km7mi63cihi.eot?t=1545807318834");
  src: url("https://at.alicdn.com/t/font_985780_km7mi63cihi.eot?t=1545807318834#iefix")
      format("embedded-opentype"),
    url("https://at.alicdn.com/t/font_985780_km7mi63cihi.woff?t=1545807318834")
      format("woff"),
    url("https://at.alicdn.com/t/font_985780_km7mi63cihi.ttf?t=1545807318834")
      format("truetype"),
    url("https://at.alicdn.com/t/font_985780_km7mi63cihi.svg?t=1545807318834#iconfont")
      format("svg");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 20px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ap {
  width: 95%;
  margin: 2rem auto;
}
.ap > p {
  font-size: 2rem;
  margin-left: 1rem;
  font-weight: 600;
}

.cl_subscribe {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin: 1rem auto 0;
  /* background-color: white; */
  border-radius: 20px;
}
.box1 {
  margin-left: 4.5rem;
}
.cl_subscribe1 {
  /* display: flex; */
  width: 100%;
  border-radius: 20px;
  margin-left: 4.2rem;
  margin-left: 5rem;
  /* background-color: white; */
}
.cl_subscribe1 > p {
  text-align: center;
}
img {
  width: 2.5rem;
  height: 2.5rem;
}
.textap {
  margin-left: -1rem;
}
.cl_subscribe > div {
  text-align: center;
  width: 10rem;
}
</style>
