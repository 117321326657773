<template>
  <div class="ap">
    <van-pull-refresh
      v-model="loading"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <div
        class="cl_task"
        v-for="(v, k) in data"
        :key="k"
        @click="
          getvisit(
            v.appointAudit,
            v.appointId,
            v.appointEvaluate,
            v.appointVisitTime
          )
        "
      >
        <!-- <div
          class="cl_visitime"
          v-if="
            v.appointAudit == 1 || v.appointAudit == 2 || v.appointAudit == 3
          "
        >
          <div>
            <span><i class="iconfont">&#xe687;</i></span
            ><span>待探视</span>
          </div>
          <van-count-down
            :time="v.surplusTime"
            format="DD 天 HH 时 mm 分 ss 秒"
          />
        </div> -->
        <div class="cl_one_ts">
          <div class="cl_time">
            <!-- <img src="" alt="" srcset="../../../assets/dsfsdhgndfg.png" /> -->
            <span
              v-if="v.appointAudit == '审核中'"
              >审核中</span
            >
            <span
              v-if="v.appointAudit == '待签到'"
              >待签到</span
            >
            <span v-if="v.appointAudit == '签到成功'">签到成功</span>
            <span v-if="v.appointAudit == '待探视'">待探视</span>
            <span v-if="v.appointAudit == '已结束'">已结束</span>
            <span v-if="v.appointAudit == '驳回'">驳回</span>
            <span v-if="v.appointAudit == '待支付'">待支付</span>
            <span v-if="v.appointAudit == '退款中'">退款中</span>
            <span v-if="v.appointAudit == '已退款'">已退款</span>
            <span v-if="v.appointAudit == '退款失败'">退款失败</span>


          </div>
        </div>
        <div class="cl_name">
          <p class="info">姓名: {{ v.userName }}</p>
          <p class="info">电话{{ v.userAccount }}</p>
          <p class="info">预约时间:{{ v.appointVisitTime }}</p>
        </div>
        <van-count-down
        v-if="v.appointAudit=='待签到'||v.appointAudit=='审核中'||v.appointAudit=='签到成功'||v.appointAudit=='待探视'||v.appointAudit=='待支付'"
          :time="v.surplusTime"
          format="DD 天 HH 时 mm 分 ss 秒"
          class="newdata"
        />
        <div class="cl_sign" v-if="v.appointAudit == '已退款'">
          <img src="../../../../public/image/refund.png" alt="" />
        </div>
        <!-- 已退款  -->
        <div class="cl_visit cl_shoer" v-else-if="v.appointAudit == '审核中'">
          审核中
        </div>
        <!-- 待审核  -->
        <div
          class="cl_sign cl_but cl_signed"
          v-else-if="v.appointAudit == '待签到'"
        >
          待签到
        </div>
        <!-- 待签到  -->
        <div
          class="cl_sign cl_but cl_signed"
          v-else-if="v.appointAudit == '待支付'"
        >
          待支付
        </div>
        <div class="cl_sign" v-else-if="v.appointAudit == '签到成功'">
          <img src="../../../../public/image/success.png" alt="" />
        </div>
        <!-- 签到成功  -->
        <div class="cl_visit cl_davist" v-else-if="v.appointAudit == '待探视'">
          待探视
        </div>
        <!-- 待探视  -->
        <!-- <div class="cl_sign" v-else-if="v.appointAudit == 1"><img src="../../../../public/image/errorsucc.png" alt=""></div>   -->
        <!-- 未签到  -->
        <!-- <div
          class="cl_sign"
          v-else-if="v.appointAudit == '待评价'"
        >
          <div class="cl_but cl_stay" @click="evaluate($event, v.appointId)">
            待评价
          </div>
          <img src="../../../../public/image/ended.png" alt="" />
        </div> -->
        <!-- 已结束待评价  -->
        <div
          class="cl_sign"
          v-else-if="v.appointAudit == '已结束'"
        >
          <img src="../../../../public/image/ended.png" alt="" />
        </div>
        <!-- 已结束已评价  -->
        <div class="cl_visit cl_apply" v-else-if="v.appointAudit == '驳回'">
          申请失败
        </div>
        <!-- 申请失败  -->
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
// import { ref } from 'vue';

import { getmine } from '@/api/home';

export default {
  name: 'manvisitComponent',
  data() {
    return {
      data: [],
      loading: false,
    };
  },
  created() {
    this.getmine();
  },
  methods: {
    getvisit(status, val, flag, time) {
      console.log(status, val, flag, time);
      switch (status) {
        case '待探视':
          this.$router.push({ path: '/tovisit', query: { id: val } }); //待探视
          break;
        case '审核中':
          this.$router.push({ path: '/details', query: { id: val } }); //审核中
          break;
        case '待签到':
          this.$router.push({path: '/signin',query: { id: val, stats: false }}); //待签到
          break;
        case '签到成功':
          this.$router.push({path: '/signin',query: { id: val, stats: true }}); //签到成功
          break;
        case '驳回':
          this.$router.push({ path: '/failed', query: { id: val } }); //申请失败
          break;
          case '待支付':
          this.$router.push({ path: '/h5Payent', query: { id: val } }); //待支付
          break;
          case '退款中':
          this.$router.push({ path: '/progress', query: { id: val } }); //待支付
          break;
        case 4:
          if (!flag) {
            this.$router.push({ path: '/endetails', query: { id: val } }); //待评价
          } else {
            this.$router.push({
              path: '/ended',
              query: {
                id: val,
                elavtime: time,
              },
            }); //已评价
          }
          break;
      }
    },
    getsignin(e, val) {
      e.stopPropagation();
      this.$router.push({ path: '/signin', query: { id: val } }); // 待签到
    },
    evaluate(e, val) {
      e.stopPropagation();
      this.$router.push({ path: '/evaluate', query: { id: val } }); // 评价
    },
    // 我的预约
    getmine() {
      getmine(0).then((res) => {
        if (res.data.code == 200) {
          this.data = res.data.data.map(item=>{
            item.appointVisitTime=item.appointVisitTime+' '+item.appointStartTime
            let into = new Date(item.appointVisitTime.replace(/-/g,'/')).getTime()>new Date().getTime()
            item.surplusTime= into?new Date(item.appointVisitTime.replace(/-/g,'/')).getTime()-new Date().getTime():0
            return item
          })
        console.log(this.data);
        }
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.getmine();
        this.loading = false;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.ap {
  width: 95%;
  margin: 0 auto;
}
.cl_task {
  width: 90%;
  height: 19.2rem;

  margin: 2rem auto 0;
  background-color: whitesmoke;
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 2rem;
  margin-bottom: 3rem;
  position: relative;
}
.cl_task:last-child {
  margin-bottom: 8rem;
}
.cl_visitime {
  width: 90%;
  margin: 1rem auto 0;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  font-weight: 500;
}
.cl_visitime span:nth-child(2) {
  margin-left: 1rem;
}
.van-count-down {
  color: #36acc6;
  font-size: 1.7rem;
  margin-top: 0.7rem;
}
.cl_group {
  background-color: whitesmoke;
  height: 3rem;
  font-size: 1.7rem;
}
.cl_sign {
  position: absolute;
  bottom: 6rem;
  right: 1.3rem;
}
.cl_visit {
  position: absolute;
  bottom: 3rem;
  right: 1.3rem;
}
.cl_sign .iconfont {
  font-size: 6rem !important;
}
/*待签到，待评价*/
.cl_but {
  color: white;
  border-radius: 200px;
  padding: 0.5rem 1.5rem;
  text-align: center;
  font-size: 1rem;
  position: absolute;
  z-index: 999;
  width: 4rem;
  margin-bottom: 11rem;
}
.cl_signed {
  background-color: #3967ff;
  bottom: 7rem;
}
.cl_stay {
  background-color: #ffdd2c;
  right: 0rem;
  top: -8rem;
}
.cl_shoer {
  color: rgb(4, 192, 29);
  font-size: 1.3rem;
  top: 2rem;
}
.cl_davist {
  color: blue;
  font-size: 1.3rem;
  bottom: 6rem;
}
.cl_apply {
  color: red;
  font-size: 1.3rem;
  bottom: 6rem;
}
.van-cell:after {
  border-bottom: 0px;
}
.van-cell {
  padding: 0;
}
.cl_one_ts {
  width: 95%;
  height: 2.4rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-content: center;
  /* padding-bottom: 0.5rem; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eaeaea;
  font-size: 1.4rem;
}
/*时间*/
.cl_time {
  /* font-weight: 900; */
  color: #505050;
  font-size: 1.4rem;
  color: #212121;
  line-height: 2rem;
}

.cl_time img {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 1rem;
}
.cl_name {
  margin-left: 1.5rem;
  height: 9.2rem;
  margin-top: 3rem;
  border-bottom: 1px solid #eaeaea;
}
.info {
  font-weight: 400;
  font-size: 45px;
  line-height: 2rem;
  /* margin-top: 0.4rem; */
  margin-bottom: 0.5rem;
}
.newdata {
  color: #df1642;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 20px;
  margin-left: 1.5rem;
padding-top: 1rem;
}
</style>
