import request from '@/utils/request';

// 订单demo预约
export function getdemo(data) {
  return request({
    url: 'pay/wechat/demo',
    method: 'post',
    data,
  });
}
//创建微信订单接口
export function CreateWeChatinterface(data) {
  return request({
    url: 'pay/wechat/create',
    method: 'post',
    data,
  });
}
//通知微信接口
export function NotifyWeChat(data) {
  return request({
    url: 'pay/wechat/advise',
    method: 'post',
    data,
  });
}
//支付结果通知
export function PaymentNotice(data) {
  return request({
    url: 'pay/wechat/advise',
    method: 'post',
    data
  });
}
