<template>
  <div class="ap">
    <van-popup v-model:show="show" :style="{ height: '20%' }" position="bottom">
      <van-radio-group v-model="checked" icon-size="3rem">
        <van-cell-group inset>
          <van-cell
            clickable
            title="微信支付"
            title-class="vant-title"
            @click="(checked = '1'), getsubmit(checked)"
          >
            <template #right-icon>
              <van-icon name="wechat" class="cl_icon cl_wechat" />
              <van-radio name="1" />
            </template>
          </van-cell>

          <van-cell
            clickable
            title="支付宝支付"
            title-class="vant-title"
            @click="(checked = '2'), getsubmit(checked)"
          >
            <template #right-icon>
              <van-icon name="alipay" class="cl_icon cl_alipay" />
              <van-radio name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-popup>
<div class="buttom-conter">
  <div class="cl_choice">
      <div class="text">￥{{ money }}/分钟</div>
      <div class="btnmoney" @click="payment">确定预约</div>
    </div>

</div>
    
  </div>
</template>

<script>
// import {ref} from 'vue'
// import { useRouter } from 'vue-router'
// import { getdemo, CreateWeChatinterface } from '@/api/wechat';
import { Toast } from 'vant';
import { getdemo } from "@/api/wechat.js";
export default {
  name: "popupComponent",
  props: {
    data: {
      required: true,
    },
    vcdata: {
      required: true,
    },
  },
  data() {
    return {
      checked: 1,
      show: false,
      money: 40,
      kdata: {},
    };
  },
  mounted() {
    console.log(this.data, this.vcdata);
  },
  methods: {
    payment() {
      // if (this.show) {
      //   this.show = false;
      // } else {
      //   this.show = true;
      // }
      // this.$router.push({ path: "/successPage" });
      this.getdemoFn();
    },
    getdemoFn() {
        let udata = {
          account: this.data.userAccount, //账户
          name: this.data.userName,    //姓名
          bed: this.data.userBed, //床号
          area: this.data.userArea,   //病区
          amount: this.money +'',
          visitTime: this.vcdata.visitTime,
          startStamp: this.vcdata.startStamp,
          endStamp: this.vcdata.endStamp,
        };
      getdemo(udata)
        .then((res) => {
          Toast.success(res.data.message);
          setTimeout(()=>{
            this.$router.push('/about')
          },500)
         
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // getsubmit(val) {
    //   let udata = {
    //     account: this.data.userAccount,
    //     name: this.data.userName,
    //     bed: this.data.userBed,
    //     area: this.data.userArea,
    //     amount: this.money * 100 + '',
    //     visitTime: this.vcdata.visitTime,
    //     startStamp: this.vcdata.startStamp,
    //     endStamp: this.vcdata.endStamp,
    //   };

    //   getdemo(udata)
    //     .then((res) => {
    //       if (res.data.code == 200) {
    //         // console.log(udata);
    //       } else {
    //         Toast.file('付款失败');
    //       }
    //     })
    //     .catch((err) => {
    //       Toast.file('创建订单失败');
    //       return err;
    //     });
    //   CreateWeChatinterface(udata)
    //     .then((res) => {
    //       // console.log(res);
    //       if (res.data.code == 200) {
    //         this.$router.push({ path: '/submit', query: { id: val } });
    //       } else {
    //         Toast.fail(res.data.message);
    //       }
    //     })
    //     .catch((err) => {
    //       Toast.file('创建订单失败');
    //       return err;
    //     });
    //   //  client/appoint/add 预约添加接口
    //   console.log(val);
    // },
  },
};
</script>

<style scoped >
.ap {
  position: relative;
}
/*按钮*/
.buttom-conter{
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 50px;
  margin-top:25rem ;
}
.cl_choice {
  box-sizing: border-box;
  height: 5.2rem;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 25px;
}

.text {
  margin-left: 1.6rem;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 24px;
  color: #df1642;
}
.btnmoney {
  width: 15.6rem;
  height: 3.6rem;
  background-color: #3d53f5;
  border-radius: 0.4rem;
  margin-right: 1.6rem;
  text-align: center;
  line-height: 3.6rem;
  font-size: 1.4rem;
  color: #fff;
}

.van-button {
  border: none;
  font-size: 1.4rem;
}
.cl_price {
  width: 45%;
  background-color: white;
  color: red;
  border-radius: 1rem 0 0 1rem;
}
.cl_button {
  width: 45%;
  color: white;
  border-radius: 0 1rem 1rem 0;
}

/*支付方式*/
.van-cell-group {
  width: 97%;
  margin: 0 auto;
}
.van-cell {
  font-size: 2rem;
  width: 100%;
  height: 8rem;
  box-sizing: border-box;
  /* margin-top: 2rem; */
}
::v-deep .vant-title {
  margin-top: 2rem;
  margin-left: 8rem;
}
/* 支付方式图标大小 */
.cl_icon {
  font-size: 3rem;
  position: absolute;
  top: 3rem;
  left: 5rem;
}
.cl_wechat {
  color: rgb(2, 198, 2);
}
.cl_alipay {
  color: blue;
}
</style>
