<template>
  <div class="ap">
    <div class="cl_image">
      <van-image
        round
        width="8rem"
        height="8rem"
        src="https://img2.baidu.com/it/u=3035872156,255770104&fm=253&fmt=auto&app=138&f=PNG?w=500&h=500"
      />
    </div>
    <div class="cl_information">
      <p class="usernema">{{ data.userName }}</p>
      <p class="usermoilber">{{ data.userAccount }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "centerComponent",
  props: {
    data: Object,
    urlimg: {
      required: true,
    },
  },
  data() {
    return {
      img: "",
    };
  },
  watch: {
    urlimg() {
      this.img = this.urlimg;
    },
  },
};
</script>

<style scoped>
.ap {
  width: 35.1rem;
  height: 18.4rem;
  margin: 6rem auto 0;
  /* display: flex; */
  /* height: 15rem; */
  flex-direction: column;
  align-content: center;
  background-color: white;
  border-radius: 20px;
  background: radial-gradient(
    112.82% 516.79% at 103.42% 95.73%,
    rgba(245, 61, 105, 0) 0%,
    rgba(255, 40, 92, 0.05) 29.13%,
    rgba(61, 83, 245, 0.04) 59.03%,
    rgba(61, 83, 245, 0) 100%
  );
}
.cl_image {
  text-align: center;
  padding-top: 2rem;
}
.cl_image img {
  width: 8rem;
}
.usernema {
  text-align: center;
  font-size: 1.6rem;
  color: #212121;
  margin-top: 0rem;
}
.usermoilber {
  text-align: center;
  font-size: 1.2rem;
  color: #9e9e9e;
  margin-top: -1.5rem;
}
</style>
