<template>
  <div class="ap">
    <van-cell-group inset>
      <van-field
        label="姓名"
        :model-value="data.userName"
        colon
        disabled
        class="cl_data_field"
      />
      <van-field
        label="手机号"
        :model-value="data.userAccount"
        colon
        disabled
        class="cl_data_field"
      />
      <van-field
        label="床号"
        :model-value="data.userBed"
        colon
        disabled
        class="cl_data_field"
      />
      <van-field
        label="病区"
        :model-value="data.userArea"
        colon
        disabled
        class="cl_data_field"
      />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: 'reousComponent',
  props: {
    data: {
      required: true,
    },
  },
  mounted() {
    this.$emit('value', this.data.id);
  },
};
</script>

<style scoped>
.ap {
  width: 100%;
  margin-top: 5rem;
}
.van-cell-group {
  /* width: 100%; */
  color: #000000;
}
.cl_data_field {
  font-size: 1.7rem;
  font-weight: 400;
  margin-top: 1rem;
  height: 5rem;
}
/deep/ .van-field--disabled .van-field__label {
  color: #000000;
}
</style>
