<template>
  <div class="ap">
    <van-pull-refresh
      v-model="loading"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <div
        class="cl_task"
        v-for="(v, k) in data"
        :key="k"
        @click="getvisit(v.appointId)"
      >
        <div class="cl_visitime">
          <div>
            <span><i class="iconfont">&#xe687;</i></span
            ><span>待探视</span>
          </div>
          <van-count-down
            :time="v.surplusTime"
            format="DD 天 HH 时 mm 分 ss 秒"
          />
        </div>
        <van-cell-group inset class="cl_max_group">
          <van-field
            label="预约人"
            :model-value="v.userName"
            readonly
            class="cl_group"
          />
          <van-field
            label="电话"
            :model-value="v.userAccount"
            readonly
            class="cl_group"
          />
          <van-field
            label="探视时间"
            :model-value="v.appointVisitTime"
            readonly
            class="cl_group"
          />
        </van-cell-group>
        <div class="cl_but">未探视</div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
// import { ref } from 'vue';

import { getmine } from '@/api/home';

export default {
  name: 'manvisitComponent',
  data() {
    return {
      data: [],
      loading: false,
    };
  },
  created() {
    this.getmine();
  },
  methods: {
    getvisit(val) {
      this.$router.push({ path: '/tovisit', query: { id: val } });
    },
    // 我的预约
    getmine() {
      getmine(1).then((res) => {
        if (res.data.code == 200) {
          this.data = res.data.data;
          // this.data = [{}];

          let new_date = Date.parse(new Date());
          for (let i = 0; i < this.data.length; i++) {
            this.data[i].appointVisitTime =
              res.data.data[i].appointVisitTime +
              ' ' +
              res.data.data[i].appointStartTime;
            let old_date = Date.parse(this.data[i].appointVisitTime);
            this.data[i].surplusTime =
              old_date > new_date ? old_date - new_date : 0;
          }
        }
        console.log(this.data);
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.getmine();
        this.loading = false;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.ap {
  width: 95%;
  margin: 0 auto;
}
.cl_task {
  width: 90%;
  margin: 1.2rem auto 0;
  background-color: whitesmoke;
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 2rem;
  margin-bottom: 3rem;
  /* position: relative; */
}
.cl_task:last-child {

  margin-bottom: 8rem;
}
.cl_visitime {
  width: 90%;
  margin: 1rem auto 0;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  font-weight: 500;
}
.cl_visitime span:nth-child(2) {
  margin-left: 1rem;
}
.van-count-down {
  color: #36acc6;
  font-size: 1.7rem;
  margin-top: 0.7rem;
}
.cl_group {
  background-color: whitesmoke;
  height: 3rem;
  font-size: 45px;
}
.cl_sign {
  /* position: absolute; */
  top: 5rem;
  right: 1.3rem;
}
.cl_sign .iconfont {
  font-size: 6rem !important;
}
.cl_but {
  color: rgb(77, 1, 239);
  font-size: 1.5rem;
  position: absolute;
  z-index: 999;
  top: 6rem;
  right: 3rem;
}
.van-cell:after {
  border-bottom: 0px;
}
</style>
