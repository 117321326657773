import axios from 'axios'
import { getToken } from '@/utils/auth'
const baseURL = {
  production: '/api',
  // development: 'http://192.168.50.123:8081'
  development: 'https://tencent.pay.demo.zjyanke.com/api'
}[process.env.NODE_ENV || 'development']

// const baseURL='http://2ai3m4.natappfree.cc'
const service = axios.create({
  baseURL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})
export default service
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const token = getToken()
    if (token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['token'] = token
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
