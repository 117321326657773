<template>
  <div class="ap">
    <van-pull-refresh
      v-model="loading"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <!-- <p>待探视</p> -->
      <div
        class="cl_task"
        v-for="(v, k) in data"
        :key="k"
        @click="
          getvisit(
            v.appointId,
            v.appointAudit,
            v.appointEvaluate,
            v.appointVisitTime
          )
        "
      >
        <div class="cl_one_ts">
          <div class="cl_time">
            <!-- <img src="" alt="" srcset="../../../assets/dsfsdhgndfg.png" /> -->
            <span
              v-if="v.appointAudit == 0"
              >待探视</span
            >
            <span v-if="v.appointAudit !== 0">已结束</span>
          </div>
        </div>
        <div class="cl_name">
          <p class="info">预约人: {{ v.userName }}</p>
          <p class="info">电话{{ v.userAccount }}</p>
          <p class="info">预约时间:{{ v.appointVisitTime }}</p>
        </div>
        <!-- <van-count-down
          :time="v.surplusTime"
          format="DD 天 HH 时 mm 分 ss 秒"
          class="newdata"
        /> -->
        <!-- <div class="cl_visitime">
          <div><span><i class="iconfont">&#xe687;</i></span><span>待探视</span></div>
          <van-count-down :time="v.surplusTime" format="DD 天 HH 时 mm 分 ss 秒" />
        </div> -->
        <!-- <van-cell-group inset class="cl_max_group">
          <van-field
            label="预约人"
            :model-value="v.userName"
            readonly
            class="cl_group"
          />
          <van-field
            label="电话"
            :model-value="v.userAccount"
            readonly
            class="cl_group"
          />
          <van-field
            label="探视时间1"
            :model-value="v.appointVisitTime"
            readonly
            class="cl_group"
          />
        </van-cell-group> -->
        <div class="cl_sign" v-if="v.appointAudit == -2">
          <img src="../../../../public/image/refund.png" alt="" />
        </div>
        <div
          class="cl_sign"
          v-else-if="v.appointAudit == 4 && v.appointEvaluate == false"
        >
          <div class="cl_but cl_stay" @click="evaluate($event, v.appointId)">
            待评价
          </div>
          <img src="../../../../public/image/ended.png" alt="" />
        </div>
        <!-- 已结束待评价  -->
        <div
          class="cl_sign"
          v-else-if="v.appointAudit == 4 && v.appointEvaluate == true"
        >
          <img src="../../../../public/image/ended.png" alt="" />
        </div>
        <!-- 已结束已评价  -->
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
// import { ref } from 'vue';

import { getmine } from '@/api/home';

export default {
  name: 'manvisitComponent',
  data() {
    return {
      data: [],
      loading: false,
    };
  },
  created() {
    this.getmine();
  },
  methods: {
    getvisit(val, status, flag, time) {
      if (status == 4) {
        if (!flag) {
          this.$router.push({ path: '/endetails', query: { id: val } }); //待评价
        } else {
          this.$router.push({
            path: '/ended',
            query: {
              id: val,
              elavtime: time,
            },
          }); //已评价
        }
      } else if (status == -2) {
        this.$router.push({ path: '/progress', query: { id: val } }); //已退款
      }
    },
    // 待探视接口
    getmine() {
      getmine(2).then((res) => {
        if (res.data.code == 200) {
          this.data = res.data.data;
          this.data = res.data.data.map(item=>{
            item.appointVisitTime=item.appointVisitTime+' '+item.appointStartTime
            let into = new Date(item.appointVisitTime.replace(/-/g,'/')).getTime()>new Date().getTime()
            item.surplusTime= into?new Date(item.appointVisitTime.replace(/-/g,'/')).getTime()-new Date().getTime():0
            return item
          })
        }
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.getmine();
        this.loading = false;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.ap {
  width: 95%;
  margin: 0 auto;
}
.ap > p {
  font-size: 2rem;
  margin-left: 1rem;
  font-weight: 600;
}
.cl_task {
  width: 90%;

  margin: 4rem auto 0;
  background-color: whitesmoke;
  border-radius: 10px;
  padding-top: 1rem;
  padding-bottom: 2rem;
  margin-bottom: 3rem;
  position: relative;
}
.cl_task:last-child {
  margin-bottom: 8rem;
}
.cl_visitime {
  width: 90%;
  margin: 1rem auto 0;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  font-weight: 500;
}
.cl_visitime span:nth-child(2) {
  margin-left: 1rem;
}
.van-count-down {
  color: #36acc6;
  font-size: 1.7rem;
  margin-top: 0.7rem;
}
.cl_group {
  background-color: whitesmoke;
  height: 3rem;
  font-size: 1.7rem;
}
.cl_sign {
  position: absolute;
  top: 2rem;
  right: 1.3rem;
}
.cl_sign .iconfont {
  font-size: 4rem !important;
}
/*待签到，待评价*/
.cl_but {
  color: white;
  border-radius: 200px;
  padding: 0.5rem 1.5rem;
  text-align: center;
  font-size: 1rem;
  position: absolute;
  z-index: 999;
  width: 4rem;
}
.cl_stay {
  background-color: #ffdd2c;
  right: 6rem;
  top: 0rem;
}

.cl_time {
  /* font-weight: 900; */
  color: #505050;
  font-size: 1.4rem;
  color: #212121;
  line-height: 2rem;
}

.cl_time img {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 1rem;
}
.cl_name {
  margin-left: 1.5rem;
  height: 9.2rem;
  margin-top: 3rem;
  border-bottom: 1px solid #eaeaea;
}
.info {
  font-weight: 400;
  font-size: 45px;
  line-height: 2rem;
  /* margin-top: 0.4rem; */
  margin-bottom: 0.5rem;
}
.cl_one_ts {
  width: 95%;
  height: 2.4rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-content: center;
  /* padding-bottom: 0.5rem; */
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eaeaea;
  font-size: 1.4rem;
}
/*时间*/
.cl_time {
  /* font-weight: 900; */
  color: #505050;
  font-size: 1.4rem;
  color: #212121;
  line-height: 2rem;
}
.newdata {
  color: #df1642;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 20px;
  margin-left: 1.5rem;
  padding-top: 1rem;
}
</style>
