import aboutComponent from '../components/about/index.vue'
import appointmentComponent from '../components/appointment/index.vue'
import homeComponent from '../components/home/index.vue'
import loginComponent from '../components/login/index.vue'
import loginauthComponent from '../components/loginauth/index.vue'
import lsetpwdComponent from '../components/lsetpwd/index.vue'
import visitComponent from '../components/tovisit/index.vue'
import broadcastComponent from '../components/broadcast/index.vue'
import detailsComponent from '../components/details/index.vue'
import paymentComponent from '../components/payment/index.vue'
import SuccessPage from '../components/payment/components/successPage'
import submitComponent from '../components/submit/index.vue'
import mytaskComponent from '../components/mytask/index.vue'
import managementComponent from '../components/management/index.vue'
import adduserComponent from '../components/adduser/index.vue'
import signinComponent from '../components/signin/index.vue'
import h5Payent from '../components/h5Payent/index.vue'
import evaluateComponent from '../components/evaluate/index.vue'
import endedComponent from '../components/ended/index.vue'
import playbackComponent from '../components/playback/index.vue'
import endetailsComponent from '../components/endetails/index.vue'
import refundComponent from '../components/refund/index.vue'
import failedComponent from '../components/failed/index.vue'
import progressComponent from '../components/progress/index.vue'
// 支付
import Payment from '../components/Paymenta/index.vue'

// 忘记密码
import ForgotPassword from '../components/forgotPassword/index.vue'
//设置密码
import SetPassword from '../components/SetPassword/index.vue'







// import VueRouter from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
// import Vue from 'vue'
// Vue.use(VueRouter);

const routes = [
	{path: '/', redirect: '/login'},
    { path: '/about',component: aboutComponent, meta:{
        showTabBar: true,
    }},
	{ path: '/appointment',component: appointmentComponent, meta:{
		showTabBar: true,
	}},
	{path: '/home', component: homeComponent, meta:{
		showTabBar:true,
	}},
	{path: '/paymenta', component: Payment, meta:{
		showTabBar:true,
	}},
	{path: '/login', component: loginComponent, meta:{
		showTabBar:false,
	}},
	{path: '/loginauth', component: loginauthComponent, meta:{
		showTabBar:false,
	}},
	{path: '/lsetpwd', component: lsetpwdComponent, meta:{
		showTabBar:false,
	}},
	{path: '/tovisit', component: visitComponent, meta:{
		showTabBar:false,
	}},
	{path: '/broadcast', component: broadcastComponent, meta:{
		showTabBar:false,
	}},
	{path: '/details', component: detailsComponent, meta:{
		showTabBar:false,
	}},
	{path: '/payment', component: paymentComponent, meta:{
		showTabBar:false,
	}},
	{path: '/successPage', component: SuccessPage, meta:{
		showTabBar:false,
	}},
	{path: '/submit', component: submitComponent, meta:{
		showTabBar:false,
	}},
	{path: '/mytask', component: mytaskComponent, meta:{
		showTabBar:false,
	}},
	{path: '/management', component: managementComponent, meta:{
		showTabBar:false,
	}},
	{path: '/adduser', component: adduserComponent, meta:{
		showTabBar:false,
	}},
	{path: '/signin', component: signinComponent, meta:{
			showTabBar:false,
		}},
		{path: '/h5Payent', component: h5Payent, meta:{
			showTabBar:false,
		}},
	{path: '/evaluate', component: evaluateComponent, meta:{
			showTabBar:false,
		}},
	{path: '/ended', component: endedComponent, meta:{
			showTabBar:false,
		}},
	{path: '/playback', component: playbackComponent, meta:{
			showTabBar:false,
		}},
	{path: '/endetails', component: endetailsComponent, meta:{
			showTabBar:false,
		}},
	{path: '/refund', component: refundComponent, meta:{
		showTabBar:false,
	}},
	{path: '/failed', component: failedComponent, meta:{
		showTabBar:false,
	}},
	{path: '/progress', component: progressComponent, meta:{
		showTabBar:false,
	}},
	{path: '/ForgotPassword', component: ForgotPassword, meta:{
		showTabBar:false,
	}},
	{path: '/SetPassword', component: SetPassword, meta:{
		showTabBar:false,
	}},
	];
const routerHistory = createWebHashHistory()
const router = createRouter({
  history: routerHistory,
  routes,
  mode: 'hash',
  base: '/warehouse-official/' //index.html 的路径
})
// router.beforeEach((to,from,next)=>{
// 	// console.log(to,from,next);
//   let username = localStorage.getItem('username');
//   if (to.name !== 'login' && !username) next({ name: 'login',query: { redirect: to.fullPath }})
//   else next() 
// })
export default router