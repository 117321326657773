<template>
	<div class="app">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" class="cl_leftarrow" />z

    <van-tabs v-model:active="activeName" animated swipeable title-active-color="orange" color="orange">
			<van-tab title="全部" name="1">
				<manall></manall>
			</van-tab>
			<van-tab title="待探视" name="2">
				<manvisit></manvisit>
			</van-tab>
			<van-tab title="已结束" name="3">
				<manended></manended>
			</van-tab>
		</van-tabs>
	</div>
</template>

<script>
	import manallComponent from './components/manall.vue'
	import manvisitComponent from './components/manvisit.vue'
	import manendedComponent from './components/manended.vue'

	import {
		ref
	} from 'vue';

	export default {
		name: 'mytaskComponent',
		components: {
			manall: manallComponent,
			manvisit: manvisitComponent,
			manended: manendedComponent,
		},
		data() {
			return {
				flag: false,
				title: '我的预约',
			}
		},
		setup() {
			let id = sessionStorage.getItem('id');
			const activeName = ref(id);
			sessionStorage.removeItem('id');

			return {
				activeName,
			};
		},
    methods: {
      // 返回上一级
      onClickLeft() {
        this.$router.push({ path: '/home'});
      }
    }
	}
</script>

<style scoped>
	.ap {
		width: 100%;
	}
  .van-tabs {
    margin-top: 4.5rem;
  }
  .cl_leftarrow {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    font-size: 30rem;
    color: rgb(3, 3, 3);
    background-color: rgb(255, 255, 255);
  }
  ::v-deep .van-nav-bar .van-icon {
    color: black !important;
  }
 
</style>
