<template>
  <div class="ap">
    <div class="cl_maxres">
      <div class="cl_ribect">
        探视对象
      </div>
      <div class="cl_max_round">
        <div class="cl_round">
          <img :src="image" />
          <p>{{ evaluate.userName }}</p>
        </div>
        <van-cell-group inset class="cl_max_group">
          <!-- <van-field label="病案号" :model-value="evaluate.recordnum" readonly class="cl_group" label-width="5rem" /> -->
          <van-field label="床号" :model-value="evaluate.userBed" readonly class="cl_group" label-width="5rem" />
          <van-field label="病区" :model-value="evaluate.userArea" readonly class="cl_group" label-width="5rem" />
          <van-field label="电话" :model-value="evaluate.userAccount" readonly class="cl_group" label-width="5rem"
            v-if="evaluate.phone" />
        </van-cell-group>
      </div>
      <div class="cl_two_visitime">
        <img src="../../../assets/et4.png" alt="" style="width: 1.8rem;height: 1.8rem">
        <div class="cl_title">探视时间</div>
        <div>{{ evaluate.appointVisitTime }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'endComponent',
  props: {
    data: {
      required: true,
      type: Object,
    },
    image: {
      required: true,
    }
  },
  data() {
    return {
      evaluate: {},
    }
  },
  watch: {
    data() {
      this.evaluate = this.data;
    }
  }

};
</script>
<style scoped>
.ap {
  width: 95%;
  margin: 7rem auto 0;
  /*border: 1px solid red;*/
  border-radius: 80px;
  font-size: 1.5rem;
}

/*探视对象*/
.cl_maxres {
  width: 95%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: white;
  height: auto;
  box-sizing: border-box;
}
.cl_ribect {
  font-size: 1.4rem;
  padding: 0.7rem 0;
  border-bottom: 0.5px solid #EEEEEE;
  width: 90%;
  margin: 0 auto;
  color: #212121;
}
.cl_max_round {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.cl_round {
  width: 40%;
  margin: 1rem auto 0;
  text-align: center;
  transform: translateY(10%);
  /*border: 1px solid red;*/
}
.cl_round img {
  width: 7rem;
  height: 7rem;
  border-radius: 1rem;
}
.cl_round>p {
  font-size: 2rem;
  margin-left: 0.7rem;
  margin-top: 0.3rem;
  padding-bottom: 1rem;
}
.van-cell-group {
  width: 60%;
}
.van-field {
  background-color: white;
}

/*探视时间*/
.cl_two_visitime {
  width: 90%;
  /*box-sizing: border-box;*/
  padding: 0.7rem 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: white;
  border-top: 0.5px solid #EEEEEE;
}
.cl_title {
  font-size: 1.4rem;
  color: #212121;
  margin-left: 1rem;
}
.cl_two_visitime div:last-child {

  margin-left: 7rem;
  font-weight: 400;
  font-size: 1.4rem;
  color: #9E9E9E;
}</style>

