<template>
  <div class="sap">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" class="cl_leftarrow" />
    <div class="info">
      <div class="user-top">
        <div class="user-img">
          <img src="../../assets/user.png" alt="" >
        </div>
        <div class="user-info">
          <div class="text">探视对象: {{ data.userName?data.userName:'--'}}</div>
          <div class="text">床号: {{ userBed?userBed:'--'}}</div>
          <div class="text">病区: {{ data.userArea?data.userArea:'--'}}</div>
          <div class="text">电话: {{ data.userAccount?data.userAccount:'--'}}</div>
        </div>
      </div>
      <div class="user-bottom">
        <img src="../../assets/et4.png" alt="" style="width: 1.8rem;height: 1.8rem">
        <div class="time">{{ time?time:'--'}}</div>
      </div>
    </div>
    <van-button type="danger" @click="getplayback(id)">探视回放</van-button>
  </div>
</template>

<script>
// import { getinfo, info } from '@/api/home.js'
import {  info } from '@/api/home.js'
import { getavatar } from '@/api/about'
export default {
  name: 'endedComponent',
  data() {
    return {
      id: -1,
      evaluateTime: '',
      image: '',
      title: '已结束',
      time:'',
      userBed:'',
      data: {}
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.time=this.$route.query.time
    this.evaluateTime = this.$route.query.elavtime;
    this.userBed = this.$route.query.userBed;
    this.inform();
  },
  methods: {
    inform() {
      // getinfo(this.id).then(res => {
      //   console.log(res)
      //   if (res.data.code == 200) {
      //     this.data = res.data.data;
      //   }
      // })
      info().then(res => {
        if (res.data.code == 200) {
          this.data = res.data.data;
        }
      });
      getavatar().then(res => {
        if (res.data.code == 200) {
          this.image = res.data.data.avatarUrl;
        }
      })
    },
    getplayback(val) {
      this.$router.push({ path: '/playback', query: { id: val } });
    },

    // 返回
    onClickLeft() {
      this.$router.push({ path: '/about' });
    }
  }
}
</script>
<style scoped lang="less">
.info{
  background-color: #fff;
  padding: 1.5rem;
  box-sizing: border-box;
  border-radius: 15px;
  margin: 1rem;
  .user-top{
    display: flex;
    .user-img{
    height: 12rem;
    width: 10rem;
    img{
      width: 100%;
      height: 100%;
    }
    }
    .user-info{
      margin-left: 2rem;
      padding: 1rem 0 ;
      font-size: 1.2rem;
      font-family: PingFangHK-Regular, PingFangHK;
      font-weight: 400;
      line-height: 2.5rem;
      color: #5B5B5B;
    }
  

  }
  .user-bottom{
    margin-top: 2rem;
    display: flex;
    align-items: center;
    .time{
      margin-left: 1.5rem;
    }
  }

}
::v-deep .van-button {
  width: 90%;
  border: none;
  font-weight: 600;
  font-size: 1.5rem;
  color: white;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: 15rem;
  background: #FFBB38;
}</style>
