<template>

  <div class="class_tabBar" v-if="$route.meta.showTabBar">
	<router-link to="/about"><div><i class="iconfont">&#xe751;</i></div><div>首页</div></router-link>
  <!-- <router-link to="/paymenta"><div><i class="iconfont">&#xe8a0;</i></div><div>支付</div></router-link> -->
	<router-link to="/appointment"><div><i class="iconfont">&#xe687;</i></div><div>预约</div></router-link>
	<router-link to="/home"><div><i class="iconfont">&#xe8a0;</i></div><div>我的</div></router-link>
  </div>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  mounted() {
    // if (window.performance.navigation.type != 1) {
    //     this.$router.push({path: '/login'});
    //   }
  },
}
// 6734
// 254813
// 254912
</script>


<style>
@import url('./static/iconfont/iconfont.css');
#app {
  /* font-family: Avenir, Hel *//* vetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
}
.iconfont {
	font-size: 2rem !important;
}
a {
  text-decoration: none;
}

.router-link-active {
  color: #3D53F5;
}
a.router-link-active {
  text-decoration: none;
}
.class_tabBar {
	width: 100%;
	position: fixed;
	z-index: 9999;
	bottom: 0;
	left: 50%;
	transform: translate(-50%);
	/* height: 5rem; */
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	font-size: 1rem;
	background-color: white;
	text-align: center;
	padding: 0.7rem 0;
}
.class_tabBar div {
	margin: 0 auto;
  /*color: #9E9E9E;*/
}

</style>
