<template>
  <div class="app">
    <!--		<van-nav-bar :title="title" class="cl_leftarrow" />-->
    <swiper />
    <p class="cl_my">我的探视</p>
    <van-pull-refresh
      v-model="loading"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
      <van-loading color="#1989fa" size="40px" vertical v-if="xflag"
        >加载中...</van-loading
      >
      <appointment :data="data" v-show="!xflag && flag"></appointment>
      <van-divider v-if="flag">没有更多啦</van-divider>
      <div class="cl_minsxd" v-if="!xflag && !flag">
        <img src="../../assets/icon.png" alt="" />
        <p>暂无探视</p>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
import swiperComponent from "./components/swiper.vue";
import appoinComponent from "./components/appointment.vue";
// import { ref } from 'vue'
import { list } from "@/api/about";
import { layout } from "@/utils/layout";
export default {
  name: "aboutComponent",
  components: {
    swiper: swiperComponent,
    appointment: appoinComponent,
  },
  data() {
    return {
      title: "首页",
      loading: false,
      data: [],
      flag: false, // 控制数据
      xflag: true, // 控制加载按钮
    };
  },
  created() {
    layout();
    this.getlist();
  },
  methods: {
    // 获取用户信息
    getlist() {
      list().then((res) => {
        var data = res.data.data;
        for (let i = 0; i < data.length; i++) {
          data[i].appointVisitTime =
            data[i].appointVisitTime +
            "  " +
            data[i].appointStartTime +
            " - " +
            data[i].appointEndTime;
        }
        this.data = data;
        setTimeout(() => {
          this.xflag = false;
          if (data.length == 0) {
            this.flag = false;
          } else {
            this.flag = true;
          }
        }, 600);
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.getlist();
        this.loading = false;
      }, 1000);
    },
  },
};
</script>
<style scoped>
.app {
  width: 100%;
  height: 100%;
}
.cl_leftarrow {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  font-size: 30rem;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
}
/*我的预约文字*/
.cl_my {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 900;
  font-size: 2rem;
  color: #212121;
  margin-left: 2rem;
  /*color: rgba(33, 33, 33, 1);*/
}
.van-pull-refresh {
  width: 100%;
  height: 65%;
  overflow: auto;
  margin: 0 auto;
  position: relative;
  /*border: 1px solid orange;*/
}
.van-divider {
  margin: 3rem 0 7rem 0;
  /*padding-bottom: 10rem;*/
}
.van-loading {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cl_minsxd {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cl_minsxd img {
  width: 16rem;
  height: 16rem;
}
.cl_minsxd p {
  text-align: center;
  font-weight: 400;
  font-size: 1.6rem;
  color: #9e9e9e;
  margin-top: 2rem;
}
</style>
