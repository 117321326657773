<template>
  <div class="ap">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" class="cl_leftarrow" />
    <div class="cl_icon">
      <img src="../../assets/Style.png" alt="">
      <p>提交成功，系统正在审核...</p>
      <p>请您耐心等待！</p>
    </div>
      <van-button type="info" size="large" @click="onClickLeft">知道了</van-button>
</div>
</template>

<script>
// import titleComponent from '../title.vue'
export default {
  name: 'submitComponent',
  components: {
    // titleComponent,
  },
  data() {
    return {
      title: '预约成功',
      id: -1,
    }
  },
  // setup() {
    // 返回上一级
    // const onClickLeft = () => history.back();
    // return {
    //   onClickLeft,
    // };
  // },
  mounted() {
    this.id = this.$route.query.id;
  },
  methods: {
    onClickLeft() {
      this.$router.push({path: '/about'});
    }

  }
}
</script>

<style scoped>
  .ap {
    width: 100%;
    height: 100%;
    background-color: white;
    border: 1px solid white;
  }
  .cl_icon {
    width: 70%;
    margin: 12rem auto 20rem;
    text-align: center;
  }
  .cl_icon img {
    width: 8.8rem;
    height: 8.8rem;
  }
  .van-icon {
    font-size: 20rem;
    color: #3967FF;
  }
  .cl_icon p {
    margin-top: 4rem;
    font-weight: 500;
    text-align: center;
    font-size: 1.4rem;

    /* Grey / 950 */

    color: #212121;
  }
  .cl_icon p:nth-child(3) {
    margin-top: 1rem;
  }
  .van-button {
    background: #3D53F5;
    border-radius: 10px;
    border: none;
    font-weight: 600;
    font-size: 1.6rem;
    color: white;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
  .cl_leftarrow {
		position: fixed;
		top: 0;
		z-index: 9999;
		width: 100%;
		font-size: 30rem;
		color: rgb(0, 0, 0);
		background-color: rgb(255, 255, 255);
	}
  ::v-deep .van-nav-bar .van-icon {
		color: black !important;
	}
</style>
