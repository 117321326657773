<template>
  <div class="subject-container">
    <van-button type="primary" @click="primaryFn">支付</van-button>
  </div>
</template>

<script>
import { h5PayFn } from "@/api/home";
export default {
  name: "subject-container",
  data() {
    return {};
  },
  methods: {
    primaryFn() {
      h5PayFn()
        .then((res) => {
          console.log(res,res.data.data);
          const url = JSON.parse(res.data.data);
        //  alert(JSON.parse(res))
        //  window.location.href=url
        window.location.href=url.h5_url // 覆盖当前页
        // alert(url.h5_url)

        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style  scoped>
</style>
