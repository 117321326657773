<template>
  <div class="spp">
    <logoComponent :title="title"></logoComponent>
    <div class="cl_form">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <!-- <van-field
            v-model="phone"
            name="phone"
            label="手机号"s
            colon
            placeholder="请输入手机号"
            :rules="[
              { required: true, message: '手机号不能为空' },
              { validator, message: '手机格式不正确' },
            ]"
          > -->
          <van-field v-model="phone" name="phone" label="手机号" colon placeholder="请输入手机号">
            <template #button>
              <van-button round color="#7fc46c" size="small" type="primary" @click="captcha" :disabled="flag">{{ button
              }}</van-button>
            </template>
          </van-field>
          <!-- <van-field
            v-model="sms"
            name="sms"
            label="验证码"
            colon
            placeholder="请输入短信验证码"
            :rules="[{ required: true, message: '请填写验证码' }]"
          /> -->
          <van-field v-model="sms" name="sms" label="验证码" colon placeholder="请输入短信验证码" />
        </van-cell-group>
        <div class="cl_button">
          <van-button round to="/login" style="background-color: whitesmoke">
            取消
          </van-button>
          <van-button round type="primary" native-type="submit">
            确定
          </van-button>
        </div>
      </van-form>
    </div>
    <!-- <van-dialog /> -->
  </div>
</template>

<script>
import logoComponent from "../login/components/logo.vue";
import { ref } from "vue";
// import {
// 	reactive
// } from 'vue';
import { useRouter } from "vue-router";
import { Dialog } from "vant";
import { Toast } from "vant";
import { registerVerify, verification } from "@/api/login";
export default {
  name: "loginauthComponent",
  components: {
    logoComponent,
  },
  setup() {
    const title = ref("修改密码");
    let username = ref("");
    let phone = ref("");
    let sms = ref("");
    let timer = ref("60");
    let button = ref("发送验证码");
    let flag = ref(false);
    const router = useRouter();

    // 用户认证 s
    const onSubmit = (values) => {
      const data = {
        userName: values.username,
        userAccount: values.phone,
        captcha: values.sms,
      };
      registerVerify(data).then((res) => {
        const data = res.data;
        if (data.code == 200) {
          // router.push({ path: '/lsetpwd', query: { id: data.data.userId } });
          router.push({ path: "/SetPassword", query: { phone: values.phone } });
        } else {
          Dialog.alert({
            message: "验证错误！",
          });
        }
      });
    };

    // 发送验证码
    function captcha() {
      // let data = {
      // 	userAccount: phone.value
      // };

      const reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(phone.value)) {
        Toast.fail("请正确填写手机号");
        return;
      }
      verification(phone.value)
        .then((res) => {
          const data = res.data.data;
          9;
          if (data.status == 200) {
            Toast("验证码发送成功");
            let clear = setInterval(() => {
              if (timer.value > 0 && timer.value <= 60) {
                flag.value = true;
                timer.value--;
                button.value = timer.value + "秒后重新发送";
              } else {
                clearInterval(clear);
                flag.value = false;
                button.value = "重新发送";
                timer.value = 60;
              }
            }, 1000);
          } else {
            Toast(data.error);

            return;
          }
        })
        .catch((err) => {
          Toast(err.error);

          return;
        });
    }
    const validator = (val) =>
      /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(
        val
      );
    return {
      title,
      username,
      phone,
      sms,
      onSubmit,
      captcha,
      button,
      timer,
      flag,
      validator,
    };
  },
};
</script>

<style scoped>
.spp {
  /* background-image: url('../../../public/image/c63e013cea559549c78a30ddf.jpg');
		background-repeat: no-repeat; */
  width: 100%;
  height: 100%;
  border: 1px solid #f9f9f9;
  position: relative;
}

.cl_form {
  width: 100%;
  margin: 8rem auto 0;
}

.cl_button {
  position: absolute;
  bottom: 6rem;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  justify-content: space-between;
  width: 80%;
}

::v-deep .cl_button .van-button {
  width: 40%;
}
</style>
