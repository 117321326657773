<template>
	<div class="sap">
		<titleComponent :title="title"></titleComponent>
		<eval-component :id="id" :visitTime="visitTime"></eval-component>
	</div>
</template>

<script>
import { layout } from '@/utils/layout';
import titleComponent from '../title.vue'
import evalComponent from './eval.vue'
// import { ref } from 'vue';
export default {
	name: 'evaluateComponent',
	components: {
		titleComponent,
		evalComponent,
	},
	data() {
		return {
			id: -1,
			visitTime: '',
			title: '评价',
		}
	},
	created() {
		this.id = this.$route.query.id;
		this.visitTime = this.$route.query.visitTime;
		layout();
	},
}
</script>
<style scoped>
.sap {
	width: 100%;
	height: 100%;
	border: 1px solid #F9F9F9;
}
</style>
