<template>
  <div class="spp">
    <van-nav-bar :title="title" class="cl_leftarrow" />
    <centerComponent :data="data" :urlimg="image"></centerComponent>
    <subscribeComponent></subscribeComponent>
    <!-- <bindingComponent></bindingComponent> -->
    <div class="bottom" @click="signOut">
      <van-button type="primary" block>退出登录</van-button>
    </div>
  </div>
</template>
<script>
import centerComponent from "./components/center.vue";
import subscribeComponent from "./components/subscribe.vue";
// import bindingComponent from './components/binding.vue'
import { Toast } from "vant";
import { info } from "@/api/home";
import { layout } from "@/utils/layout";
import { getavatar } from "@/api/about";
export default {
  name: "homeComponents",
  components: {
    centerComponent,
    subscribeComponent,
    // bindingComponent,
  },
  data() {
    return {
      title: "我的",
      data: {},
      image: "",
    };
  },
  created() {
    layout();
    this.getinfo();
    getavatar().then((res) => {
      if (res.data.code == 200) {
        this.image = res.data.data.avatarUrl;
      }
    });
  },
  methods: {
    getinfo() {
      info().then((res) => {
        let obj = res.data;
        if (obj.code == 200) {
          this.data = obj.data;
        } else {
          Toast.error("网络错误");
          this.$router.push({ path: "/login" });
        }
      });
    },
    signOut() {
      this.$router.push({ path: "/login" });
    },
  },
};
</script>
<style scoped>
.cl_leftarrow {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  font-size: 30rem;
  color: rgba(51, 51, 51, 1);
  font-family: PingFangHK-Medium;
  background-color: rgb(255, 255, 255);
}
.spp {
  width: 100%;
  height: 100%;
  border: 1px solid #f9f9f9;
}
.bottom {
  margin-top: 12rem;
  padding: 0 2rem;
}
</style>
