export function dCionFn(val,type){
  
    const arr = [
      "不打折",
      "1折",
      "2折",
      "3折",
      "4折",
      "5折",
      "6折",
      "7折",
      "8折",
      "9折",
    ]
   
    // type==1折扣转换成数字
    if(type=='1'){
    return  arr.indexOf(val)
    }else if(type=='2'){
    return  arr[val]
    }
  
  }