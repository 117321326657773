<template>
  <div class="app">
    <titleComponent :title="title"></titleComponent>
    <reousComponent :data="data.list"></reousComponent>
    <!--		<van-notice-bar left-icon="volume-o" :text="text" />-->
    <popupComponent :data="data.list" :vcdata="vcdata"></popupComponent>
  </div>
</template>

<script>
import reousComponent from './components/reous.vue';
import titleComponent from '../title.vue';
import popupComponent from './components/popup.vue';
import { obtain } from '@/api/appointment';
import { reactive } from 'vue';
import { layout } from '@/utils/layout';
// import {ref} from 'vue'

export default {
  name: 'paymentComponent',
  components: {
    reousComponent,
    titleComponent,
    popupComponent,
  },
  data() {
    return {
      title: '确认预约',
      val: -1,
      text: '此次操作有金额交易，请注意自身财产！',
      vcdata: {},
    };
  },
  created() {
    this.vcdata = JSON.parse(decodeURIComponent(this.$route.query.row));
    console.log(this.vcdata);
  },
  setup() {
    // 返回上一级
    const onClickLeft = () => history.back();
    const data = reactive({
      list: [],
    });
    obtain().then((res) => {
      data.list = res.data.data;
      layout();
    });
    return {
      onClickLeft,
      data,
    };
  },
};
</script>

<style scoped>
.app {
  width: 100%;
  height: 80%;
  border: 1px solid #f9f9f9;
}

/deep/.van-notice-bar {
  margin-top: 8rem;
  font-size: 1.6rem;
  height: 5rem;
}
</style>
