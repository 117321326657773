<template>
  <div class="spp">
    <logoComponent :title="title"></logoComponent>
    <div class="cl_form">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="newPassword"
            name="newPassword"
            label="新密码"
            colon
            placeholder="请输入密码"
          />
          <van-field
            v-model="cPassword"
            name="cPassword"
            label="确认密码"
            colon
            placeholder="请输入密码"
          />
        </van-cell-group>
        <div class="cl_button">
          <van-button round to="/login" style="background-color: whitesmoke">
            取消
          </van-button>
          <van-button round type="primary" native-type="submit">
            确定
          </van-button>
        </div>
      </van-form>
    </div>
    <!-- <van-dialog /> -->
  </div>
</template>

<script>
import logoComponent from "../login/components/logo.vue";
import { ref } from "vue";
// import {
// 	reactive
// } from 'vue';
import {
		useRoute
	} from 'vue-router';
// import { Dialog } from "vant";
import { Toast } from "vant";
import { updatePassword } from "@/api/login";
import { useRouter } from "vue-router";
// import { setToken } from '@/utils/auth';
// import { mobileRules, codeRules, mobileRules1 } from './rules';   //校验规则表单上
export default {
  name: "loginauthComponent",
  components: {
    logoComponent,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const title = ref("设置密码");
    let newPassword = ref("");
    let cPassword = ref("");
    let timer = ref("60");
    let button = ref("发送验证码");
    let flag = ref(false);
    let phone = ref(route.query.phone);

    // 用户认证 
    const onSubmit = (values) => {
      console.log(phone.value);
      const data = {
        password: values.newPassword,
        passwords: values.cPassword,
        userAccount: phone.value,
      };
      updatePassword(data).then((res) => {
        const data = res.data.data;
        console.log(res.data);
        if (data.status == 200) {
          Toast.success({
            message: "修改密码成功",
          });
          setTimeout(()=>{
            router.push({ path: "/login"});
          },2000)
          
        } else {
          Toast.fail(data.error)
        }
      });
    };

    return {
      title,
      onSubmit,
      button,
      newPassword,
      cPassword,
      timer,
      flag,
    };
  },
};
</script>

<style scoped>
.spp {
  /* background-image: url('../../../public/image/c63e013cea559549c78a30ddf.jpg');
		background-repeat: no-repeat; */
  width: 100%;
  height: 100%;
  border: 1px solid #f9f9f9;
  position: relative;
}

.cl_form {
  width: 100%;
  margin: 8rem auto 0;
}
.cl_button {
  position: absolute;
  bottom: 6rem;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  justify-content: space-between;
  width: 80%;
}
::v-deep .cl_button .van-button {
  width: 40%;
}
</style>
