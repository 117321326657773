<template>
	<div class="app">
		<div v-show="!flag">
			<van-cell-group inset>
				<van-field v-model="rousers.bindName" label="姓名" placeholder="暂无" disabled label-class="cl_ddit" />
				<van-field name="radio" label="性别" disabled label-class="cl_ddit">
					<template #input>
						<van-radio-group v-model="rousers.bindSex" direction="horizontal" disabled>
							<van-radio name="0">男</van-radio>
							<van-radio name="1">女</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field v-model="rousers.bindAccount" label="手机号码" placeholder="暂无" disabled label-class="cl_ddit" />
				<van-field v-model="rousers.bindRelation" label="亲属关系" placeholder="暂无" disabled label-class="cl_ddit" />
				<!-- <van-field v-model="bedata.userAge" label="年龄" placeholder="暂无" disabled label-class="cl_ddit" /> -->
				<van-field v-model="bedata.userBed" label="床号" placeholder="暂无" disabled label-class="cl_ddit" />
				<van-field v-model="bedata.userArea" label="病区" placeholder="暂无" disabled label-class="cl_ddit" />
			</van-cell-group>
			<van-button type="primary" size="large" @click="getedit">编辑</van-button>
		</div>
		<div v-show="flag">
			<van-form @submit="onSubmit">
				<van-cell-group inset>
					<van-field v-model="rousers.bindName" name="bindName" label="姓名" placeholder="请填写姓名" required
						:rules="[{ required: true, message: '请填写姓名' }]" label-class="cl_ddit" />
					<van-field name="bindSex" label="性别" required :rules="[{ required: true, message: '请选择性别' }]"
						label-class="cl_ddit">
						<template #input>
							<van-radio-group v-model="rousers.bindSex" direction="horizontal">
								<van-radio name="0">男</van-radio>
								<van-radio name="1">女</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<van-field v-model="rousers.bindAccount" name="bindAccount" label="亲属关系" placeholder="请输入亲属关系" required
						:rules="[{ required: true, message: '请输入亲属关系' }]" label-class="cl_ddit" />
					<van-field v-model="rousers.bindRelation" name="bindRelation" type="tel" label="手机号码"
						placeholder="请输入手机号" required :rules="[{ required: true, message: '请输入手机号' }]"
						label-class="cl_ddit" />
					<van-field v-model="bedata.userAge" label="年龄" placeholder="暂无" disabled label-class="cl_ddit" />
					<van-field v-model="bedata.userBed" label="床号" placeholder="暂无" disabled label-class="cl_ddit" />
					<van-field v-model="bedata.userArea" label="病区" placeholder="暂无" disabled label-class="cl_ddit" />
				</van-cell-group>
				<van-button type="primary" native-type="submit">保存</van-button>
			</van-form>
		</div>
	</div>
</template>

<script>
import {
	ref
} from 'vue';

// import {reactive} from 'vue';
// import { useRouter } from 'vue-router';
export default {
	name: 'editComponent',
	props: {
		detalis: {
			required: true,
		},
		editdata: {
			required: true,
		},
	},
	data() {
		return {
			rousers: {},
			bedata: {},
			// userAge: this.editdata.userAge,
			// userBed: this.editdata.userBed,
			// userArea: this.editdata.userArea,
			data: {
				bindName: '',
				bindAccount: '',
				bindSex: '',
				bindRelation: '',
			}
		}
	},
	watch: {
		detalis() {
			this.rousers = this.detalis;
		},
		editdata() {
			this.bedata = this.editdata;
		}
	},
	methods: {
		onSubmit(values) {
			this.$emit('getedit', values);
		}
	},
	setup() {
		// let data = props.details;
		// const checked = ref(data.bindSex);
		// const router = useRouter();
		let flag = ref(false);
		function getedit() {
			if (flag.value) {
				flag.value = false;
			} else {
				flag.value = true;
			}
		}
		return {
			// data,
			// checked,
			getedit,
			flag,
		};
	},
}
</script>
<style scoped>
.app {
	width: 100%;
	position: relative;
	margin-top: 7rem;
}
::v-deep .van-cell-group {
	width: 80%;
	margin: 4rem auto 2rem;
	/* border: 1px solid red; */
}
::v-deep .van-field {
	font-size: 1.6rem;
	height: 6rem;
}
::v-deep .cl_ddit {
	width: 9rem;
	font-weight: 900;
}
.cl_quxi {
	font-weight: 550;
	font-size: 1.6rem;
	margin-left: 2.5rem;
}
::v-deep .van-radio-group {
	font-size: 1.6rem;
}
::v-deep .van-radio:last-child {
	margin-left: 2rem;
}
::v-deep .van-button {
	position: absolute;
	bottom: -15rem;
	width: 70%;
	left: 50%;
	transform: translate(-50%);
	border-radius: 10rem;
}</style>
