<template>
  <div class="ap">
    <div
      class="cl_ts"
      v-for="(item, k) in roudata"
      :key="k"
      @click="govisit(item.appointAudit, item.appointId,item)"
    >
      <div class="cl_one_ts">
        <div class="cl_time">
          <img src="" alt="" srcset="../../../assets/dsfsdhgndfg.png" />{{
            item.appointVisitTime
          }}
        </div>
      </div>
      <div class="cl_two_ts">
        <div class="cl_mours">
          <div class="cl_image"><img src="../../../assets/user.png" alt="" /></div>
          <div class="cl_name">
            <p>姓名: {{ item.userName }}</p>
            <p>床号：{{ item.userBed }}</p>
            <p>病区：{{ item.userArea }}</p>
          </div>
        </div>
        <div
          class="cl_status"
          v-if="(item.appointAudit == '退款中')"
          style="color: #e68113; background-color: #fff8eb"
        >
          退款中
        </div>
        <div
          class="cl_status"
          v-if="(item.appointAudit == '已退款')"
          style="color: #e68113; background-color: #fff8eb"
        >
          已退款
        </div>
        <!-- <div
          class="cl_status"
          v-else-if="(item.appointAudit == -3)"
          style="color: #e61313; background-color: #eceefe"
        >
          退款失败
        </div> -->
        <div
          class="cl_status"
          v-if="item.appointAudit == '审核中'"
          style="color: #e68113; background-color: #fff8eb"
        >
          审核中
        </div>
        <div
          class="cl_status"
          v-else-if="item.appointAudit == '待签到'"
          style="color: blue; background-color: #eceefe"
        >
          待签到
        </div>
        <div
          class="cl_status"
          v-else-if="item.appointAudit == '签到成功'"
          style="color: blue; background-color: #eceefe"
        >
          签到成功
        </div>
        <div
          class="cl_status"
          v-else-if="item.appointAudit == '待探视'"
          style="color: #28a7c2 ;background-color: #eceefe"
        >
          待探视
        </div>
        <div
          class="cl_status"
          v-else-if="item.appointAudit == '待支付'"
          style="color: #28a7c2 ;background-color: #eceefe"
        >
          待支付
        </div>
        <div
          class="cl_status"
          v-else-if="item.appointAudit == '驳回'"
          style="color: #e61313; background-color: #eceefe"
        >
        已驳回
        </div>
        <div
          class="cl_status"
          v-else-if="item.appointAudit == '已结束'"
          style="color: #e61313; background-color: #eceefe"
        >
        已结束
        </div>
        <div class="cl_res">查看详情<span>></span></div>
        <!-- <van-tag
          class="cl_reservation"
          plain
          type="primary"
          v-if="item.appointAudit == 0"
          >取消预约</van-tag
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import { getavatar } from '@/api/about';
// import { reactive } from 'vue';
export default {
  name: 'appoinComponent',
  props: {
    data: Array,
  },
  data() {
    return {
      image: '',
      roudata: [],
    };
  },
  watch: {
    data() {
      this.roudata = this.data;
      // console.log(this.roudata);
    },
  },
  created() {
    getavatar().then((res) => {
      if (res.data.code == 200) {
        this.image = res.data.data.avatarUrl;
      }
    });
  },
  methods: {
    govisit(val, uid , item) {
      const time=item.appointVisitTime
      // console.log(uid);
      if (val == '审核中') {
        this.$router.push({ path: '/details', query: { id: uid } }); //审核中
      } else if (val == '待签到') {
        this.$router.push({
          path: '/signin',
          query: { id: uid, stats: false },
        }); //待签到
      } else if (val == '签到成功') {
        this.$router.push({ path: '/signin', query: { id: uid, stats: true } }); //签到成功
      } else if (val == '待探视') {
        this.$router.push({ path: '/tovisit', query: { id: uid } }); //待探视
      } 
      else if (val == '待支付') {
        this.$router.push({ path: '/h5Payent', query: { id: uid } }); //待支付
      }else if (val == '驳回') {
        this.$router.push({ path: '/failed', query: { id: uid } }); //申请失败
      }else if(val == '退款中'){
        this.$router.push({ path: '/progress', query: { id: 2 } }); //退款中
      }else if(val == '已退款'){
        this.$router.push({ path: '/progress', query: { id: 3 } }); //已退款
      }else if(val == '已结束'){
        this.$router.push({ path: '/ended', query: { id: uid ,time:time,userBed:item.userBed} }); //已结束
      }
      console.log(uid);
    },
  },
};
</script>

<style scoped>
@import url('//at.alicdn.com/t/font_8d5l8fzk5b87iudi.css');
/* .ap {
  width: 90%;
} */
.cl_ts {
  width: 35.1rem;
  height: 19.8rem;
  margin: 0 auto 2rem auto;
  /*background-color: rgb(248, 247, 247);*/
  /*border-radius: 100px;*/
  /* box-shadow: 50px; */
  /*box-shadow: 0.1rem 0.1rem 1rem 0.1rem #d1caca;*/
  background: #ffffff;
  border-radius: 1.2rem;
}
/*时间及状态*/
.cl_one_ts {
  width: 95%;
  height: 2.4rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-content: center;
  /* padding-bottom: 0.5rem; */
  padding-top: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eaeaea;
  font-size: 1.4rem;
}
/*时间*/
.cl_time {
  /* font-weight: 900; */
  color: #505050;
  font-size: 1.4rem;
  color: #212121;
  line-height: 2rem;
}
.cl_time img {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 1rem;
}
/*状态*/
.cl_status {
  font-weight: 400;
  margin-right: -14rem;
  margin-bottom: 7rem;
  width: 6.65333rem;
  height: 3.2rem;
  text-align: center;
  line-height: 3.2rem;
  border-radius: 15px;
}
.cl_two_ts {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  align-items: center;
  position: relative;
}
.cl_mours {
  display: flex;
  /* align-items: center; */
}
.cl_image {
  margin-top: 1.1rem;
}
.cl_image img {
  width: 8.2rem;
  height: 11rem;
  border-radius: 20px;
}
.cl_name {
  margin-left: 1.5rem;
}
.cl_name p {
  font-size: 1.6rem;
  list-style: 2.8rem;
  color: #333333;
  /* font-weight: 400; */
  /* line-height: 2.5rem; */
  /* margin-bottom: 0.4rem; */
}
.cl_res {
  /*margin-top: 3rem;*/
  margin-right: 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: #777777;
  margin-top: 9.3rem;
}
.res span {
  font-size: 0.9rem;
  font-weight: 600;
}
/* 取消预约 */
.cl_reservation {
  position: absolute;
  bottom: 0rem;
  right: 0;
  /*border: 1px solid blue;*/
  /*border-radius: 100px;*/
  /*color: blue;*/
  font-size: 1rem;
  /*padding: 0.2rem 0.6rem;*/
}
</style>
