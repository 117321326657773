<template>
	<div class="app">
		<titleComponent :title="title"></titleComponent>
		<relsourComponent :val="id" :data="data" :unix="unix" :image="image"></relsourComponent>
    <div class="buttom">
      <van-button type="primary"  block v-if="flag === 'true'" @click="$router.push('/about')">返回首页</van-button>
      <van-button type="primary" block v-if="flag === 'false'" @click="getsignin" style="margin-bottom:20px;">签到</van-button>
      <van-button type="primary" block v-if="flag === 'false'"  color="red" @click="tuiKuan">退款</van-button>
    </div>

  </div>
</template>

<script>
	import titleComponent from '../title.vue'
	import relsourComponent from '../tovisit/components/visit.vue'
  import { Toast } from 'vant';
  import { getlist,check, getavatar } from '@/api/about';
import { layout } from '@/utils/layout';
	export default {
		name: 'signinComponent',
		components: {
			titleComponent,
			relsourComponent,
		},
		data() {
			return {
				id: -1,
        unix: -1,
				title: '预约详情',
        button: '签到',
        flag: false,
        data: {},
        image: '',
			}
		},
		created() {
			this.id = this.$route.query.id;
      this.flag = this.$route.query.stats;
      layout();
      this.getlist();
		},
    methods: {
      getsignin() {
        check(this.id).then(res => {
          if (res.data.code == 200) {
            this.flag = 'true';
            Toast.success('签到成功');
          } else {
            Toast.fail(res.data.data.error);
          }
        })
      },
      tuiKuan(){
        this.$router.push({ path: '/refund', query: { appointId: this.data.appointId ,userName:this.data.userName,orderAmount:this.data.orderAmount,userBed:this.data.userBed,cancelReason:''} })
      },
      getlist() {
        getlist(this.id).then(res => {
          if (res.data.code == 200) {
            let data = res.data.data;
            let dateTime = data.appointVisitTime+ ' ' + data.appointStartTime;
            data.appointVisitTime = dateTime + ' - ' + data.appointEndTime;
            let new_date = Date.parse(new Date());
            let old_date = Date.parse(dateTime);
            this.unix = old_date > new_date ? old_date - new_date : 0;
            this.data = data;
            console.log('data:',this.data);
          } else {
            Toast.fail('网络错误');
            this.$router.push({path: '/about'});
          }
        });
        getavatar().then(res => {
          if(res.data.code == 200) {
            this.image = res.data.data.avatarUrl;
          }
        })
      }
    }
	}
</script>

<style scoped>
	.app {
		width: 100%;
		height: 100%;
    border: 1px solid #F9F9F9;

	}
  .buttom{
    
    margin-top: 150px;
    padding: 0 80px;
  }
  /* ::v-deep .van-button {
    width: 80%;
    border: none;
    font-weight: 600;
    font-size: 1.5rem;
    color: white;
    background-color: #3D53F5;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 20rem;
    border-radius: 0.5rem;
  } */
  .cl_active {
    background-color: #666666;
  }
</style>
