<template>
	<div class="cl_ref_datime">
		<div class="cl_datime" :class="[current == k ? 'currecl_datime': 'cl_datime', !item.status ? 'a' : '']" v-for="(item,k) in data" :key="k" @click="getment(k,item.status)" >
			<div>{{item.week}}</div>
			<div>{{item.days}}</div>
			<div v-if="item.status == true">预约</div>
			<div v-if="item.status == false">不可约</div>
		</div>
	</div>
</template>
<script>
import { Toast } from 'vant';
import { ruleinfo } from '@/api/appointment'
	export default {
		name: 'apptimeComponent',
		props: {
			tabledata: Array,
		},
		data() {
			return {
				current: -1,
				data: this.tabledata,
			}
		},
		methods: {
			getment(index,status) {
				if (status) {
					this.current = index;
					let data = {
						lookingDate: this.GetDateStr(index)
					}
					this.$emit('loacalData',data);
					ruleinfo(data).then(res => {
						if(res.data.code == 200) {
							this.$emit('formdata',res.data.data);
						} else {
							Toast.fail(res.data.data.error)
							this.$emit('formdata',[]);
						}
					})
				} else {
					Toast.fail('不可预约')
				}
			},

			GetDateStr(AddDayCount) {
				var dd = new Date();
				dd.setDate(dd.getDate() + AddDayCount);//获取AddDayCount天后的日期
				var y = dd.getFullYear();
				var m = (dd.getMonth() + 1) < 10 ? "0" + (dd.getMonth() + 1) : (dd.getMonth() + 1);//获取当前月份的日期，不足10补0
				var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();//获取当前几号，不足10补0
				return y + "-" + m + "-" + d;
			}
		},
	}
</script>
<style scoped>
	.cl_ref_datime {
		width: 95%;
		margin: 2rem auto;
		border-radius: 20px;
		background-color: whitesmoke;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		text-align: center;
	}
	.cl_datime {
		width: 14%;
		font-size: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		padding: 0.5rem;
		/* border: 1px solid rgb(0, 0, 0); */
		border-radius: 10px;
	}
	.cl_datime div {
		margin: 0.8rem 0;
	}
	.cl_datime div:nth-child(2) {
		/* background-color: orange; */
		width: 3rem;
		height: 3rem;
		text-align: center;
		margin: 0 auto;
		color: rgb(5, 5, 5);
		line-height: 3rem;
		border-radius: 50%;
	}
	.currecl_datime {
		width: 14%;
		font-size: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		padding: 0.5rem;
		/*border: 1px solid orange;*/
		border-radius: 5px;
    color: white !important;
    background-color: #18A957;
    border-radius: 10px;
	}
	.currecl_datime div:nth-child(2) {
		/*background-color: #18A957;*/
		width: 3rem;
		height: 3rem;
		text-align: center;
		margin: 0 auto;
		color: white;
		line-height: 3rem;
		border-radius: 50%;
	}
	.a div:nth-child(3) {
		color: red;
	}
</style>
