<template>
	<div class="swiper">
		<!-- <div class="swiper-wrapper">
			<div class="swiper-slide" v-for="(k,i) in picture" :key="i">
				<img :src="k ">
			</div>
		</div> -->
		<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
			<van-swipe-item v-for="(k,i) in picture" :key="i">
				<img src="../../../assets/Image.png">
			</van-swipe-item>
		</van-swipe>
<!--    <img :src="image" class="my_image">-->
	</div>
</template>

<script>
	import 'swiper/css';
	import Swiper from 'swiper';	//引入swiper
import { getchart } from '@/api/about';

	export default {
		name: 'swiperComponent',
		data() {
			return {
				picture: ['../../../assets/Image.png'],
        // image: require('../../../../public/Image.png')
			}
		},
		created() {
			getchart().then(res => {
				if (res.data.code == 200) {
					// this.picture = res.data.data.chartUrl;
				}
			})
		},
		mounted() {
			new Swiper('.swiper', {
				autoplay: true,//可选选项，自动滑动
				loop: true,
			})

		},

	}
</script>

<style scoped>
	.swiper {
		width: 100%;
    height: 20rem;
		/*margin-top: 5rem;*/
	}
	/* .swiper-wrapper {
		width: 100%;
		height: 20rem;
	}
	.swiper-slide {
		width: 100%;
	}
	.swiper-slide img {
		width: 100%;
	} */

	.my-swipe .van-swipe-item {
		width: 100%;
		height: 100%;
    border-radius: 0px 0px 20px 20px;
  }
  .van-swipe-item img {
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 20px 20px;
  }
  .my_image {
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 20px 20px;
  }
</style>
