<template>
  <div class="ap">
    <van-search
      background="#f9f9f9"
      v-model="value"
      placeholder="搜索"
      class="cl_search"
      input-align="center"
    />
    <div class="cl_plus" @click="getadd">+</div>
  </div>
</template>

<script>
// import { ref } from 'vue';

export default {
  name: 'searchComponent',
  data() {
    return {
      value: '',
    };
  },
  watch: {
    // value: function (val) {
    //   this.$emit('search', val);
    // },
  },
  methods: {
    getadd() {
      this.$router.push({ path: '/adduser' });
    },
  },
};
</script>

<style scoped>
.ap {
  width: 95%;
  margin: 6rem auto 0;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
}
.cl_search {
  width: 80%;
  /* background-color: #ffffff; */
  text-align: center;
}
.cl_plus {
  width: 3.6rem;
  height: 3.6rem;
  background: #3d53f5;
  border-radius: 0.6rem;
  text-align: center;
  line-height: 3.6rem;
  color: #ffffff;
  font-size: 2.6rem;
}
.van-search__field {
  background-color: #f5f5f5;
}
/deep/ .van-search__content {
  border: 0.2px solid #333;
  border-radius: 6px;
}
</style>
