<template>
	<div class="ap">
		<van-cell-group inset>
			<van-field label="预约人" :model-value="data.userName" readonly colon class="cl_data_field" />
			<van-field label="电话" :model-value="data.userAccount" colon readonly class="cl_data_field" />
			<van-field label="床号" :model-value="data.userBed" colon readonly class="cl_data_field" />
			<van-field label="病区" :model-value="data.userArea" colon readonly class="cl_data_field" />
			<van-field label="预约时间" :model-value="data.appointVisitTime" colon readonly class="cl_data_field" />
			<van-field label="驳回原因" :model-value="data.appointReject" readonly rows="1" autosize type="textarea" colon
				class="cl_data_field" style="margin-bottom: 2rem;" />
		</van-cell-group>
	</div>
</template>
<script>
export default {
	name: 'reousComponent',
	props: {
		data: {
			required: true,
			type: Object,
		}
	},
};
</script>
<style scoped>
.ap {
	width: 100%;
	margin-top: 7rem;
}
.cl_data_field {
	font-size: 1.7rem;
	font-weight: 400;
	height: 6rem;
}
</style>
