<template>
  <div class="app">
    <div class="box" v-if="isSwict">
       <img src="@/assets/tiaozhuan.png" alt="">
    </div>
    <logoComponent :title="title"></logoComponent>
    <van-form @submit="onSubmit">
      <div class="cl_form">
        <van-cell-group inset>
          <van-field
            v-model="username"
            name="username"
            label-width="20px"
            colon
            icon="shop-o"
            placeholder="请输入账号"
            :rules="[{ required: true, message: '请填写用户名' }]"
          >
            <template #label>
              <van-icon name="manager" />
            </template>
          </van-field>
          <van-field
            v-model="password"
            type="password"
            name="password"
            label-width="20px"
            colon
            placeholder="请输入密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          >
            <template #label>
              <van-icon name="lock" />
            </template>
          </van-field>
        </van-cell-group>
      </div>
      <div class="login">
         <div class="authentication-conter" >
          <div class="left"  @click="forgotPassword">忘记密码</div>
          <div class="rigth" @click="authen">用户认证</div>
         </div>
        <van-button
          round
          block
          type="primary"
          native-type="submit"
          color="#3D53F5"
          >登录</van-button
        >
      </div>
    </van-form>
    <!-- <van-dialog /> -->
  </div>
</template>

<script>
import logoComponent from "./components/logo.vue";
import { ref } from "vue";
// import { reactive } from 'vue';

import { useRouter } from "vue-router";
import { Dialog, Toast } from "vant";

import { login } from "@/api/login";
import { setToken } from "@/utils/auth";
// import { mobileRules, codeRules, mobileRules1 } from './rules';   //校验规则表单上

export default {
  name: "loginComponent",
  components: {
    logoComponent,
  },
  setup() {
    const title = ref("登录");
    const username = ref("");
    const password = ref("");
    const router = useRouter();
    function authen() {
      router.push({ path: "/loginauth" });
    }
    const onSubmit = (values) => {
      const data = {
        userAccount: values.username,
        userPassword: values.password,
      };
      login(data).then((res) => {
        const data = res.data;
        if (data.code == 200) {
          setToken(data.data.token);
          localStorage.setItem("mobile", values.username);
          router.push({ path: "/about" });
          Toast.success("登录成功");
        } else {
          Dialog.alert({
            message: "账号或密码错误！",
          });
        }
      });
    };
    return {
      title,
      username,
      password,
      onSubmit,
      authen,
    };
  },
   data(){
    return{
      isSwict:false
    }
   },
  mounted() {
    this.is_weixin()
  },
  methods:{
    is_weixin(){
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        // alert('微信打开')
        this.isSwict=true
        return true;
      } else {
        this.isSwict=false
        // alert('浏览器打开')
        return false;
      }
    },
    // 忘记密码
    forgotPassword(){
         this.$router.push({path:'/ForgotPassword'})
    }

  }
};
</script>

<style scoped lang="less">
.box{
  width: 100%;
  height: 100%;
  background-color:#3d53f5;
  position:absolute;

  z-index: 999;
  img{
    width: 100%;
    height: 100%;

  }
}
.app {
  /* background-image: url('../../../public/image/c63e013cea559549c78a30ddf.jpg');
		background-repeat: no-repeat; */
  position: relative;
  height: 100%;
  border: 1px solid #f9f9f9;
}
.cl_form {
  width: 90%;
  margin: 8rem auto 0;
  padding: 2rem 0;
  background-color: white;
  border-radius: 20px;
}
.authentication-conter {
  width: 100%;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: absolute;
  font-size: 50px;
  font-weight: 500;
  font-family: PingFangHK-Regular;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 3rem;
  .rigth{
    color: #3d53f5;
  }
}
::v-deep .van-button {
  width: 70%;
  /* position: absolute; */
  bottom: -12rem;
  left: 50%;
  transform: translate(-50%);
}
.login {
  width: 100%;
  height: 200px;
}
</style>
