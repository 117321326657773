<template>
    <div  class='outside-subject-container'>
        <titleComponent title="预约成功" :style="{ height: '14%' }" />
         <div class="title-img">
            <div class="img-box">
                <van-icon name="checked" size="10rem" color="#1989fa" />
            </div>
            <div class="title-box">
                <span>提交成功，系统正在审核...</span>
                <span>请耐心等待。</span>
            </div>
         </div>
         <div class="bottom-box">
         <van-button type="primary" block @click="$router.push({path:'/about'})">知道了</van-button>
         </div>
    </div>
</template> 

<script>
import titleComponent from '@/components/title.vue';
export default {
    components:{titleComponent},
    data() {
        return {

        }
    }
}
</script>

<style lang="less"  scoped>
.outside-subject-container{
    width: 100%;
    height: 100%;
    .title-img{
        height: 40rem;
        .title-box{
            width: 100%;
            text-align: center;
            font-size: 1.5rem;
            span{
                display: block;

            }

        }
        .img-box{
        width: 100%;
        text-align: center;
        margin: 1rem 0 3rem;
        }
    }
    .bottom-box{
        margin: 6rem 0 0;
        padding: 0 2rem;
    }

}


</style>